
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButton, 
  IonText,
  IonIcon,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  onIonViewDidEnter,

} from '@ionic/vue';
import { defineComponent, computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import t from "@/translation"
import { guestStatuses } from '@/lib/guest-statuses'
import { apiEndpoints } from '@/lib/api-endpoints';
import ExportButton from '@/components/ExportButton.vue';

import {
  addCircleOutline,
  enterOutline,
  searchOutline,
} from 'ionicons/icons'
import { Guest } from '@/lib/api-endpoints';

export default  defineComponent({
  name: 'Guests',
  components: { 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonPage, 
    IonButton, 
    IonText,
    IonIcon,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonNote,

    ExportButton,
  },
  setup() {

    const router = useRouter();
    const store = useStore();

    onIonViewDidEnter(() => {

      store.dispatch("getGuests");

    });

    

    function onImportClicked(){
      router.push('/tabs/guests/import');
    }

    function onAddClicked(){
      router.push('/tabs/guests/guest');
    }

    function onSearchClicked(){
      router.push('/tabs/guests/list')
    }
    
    function onStatusClicked(statusId: number){
      router.push(`/tabs/guests/list/${statusId}`)
    }


    const guestStats = computed(() => {

      const countStats = (arr: Guest[], status: number) => {
        let counter = 0;
        arr.forEach( obj => {
          if (obj.invited == status){
            counter += 1;
          }
        });
        return counter;
      };

      const result: {[key: string]: number} = {};
      for(const stat of guestStatuses){
        result[stat[0]] = countStats(store.state.guests, stat[1].statusId);
      }
      return result;

    });

    const summaryRowList = computed(()=>[
      {
        title:t("Guests Total"),
        value:guestStats.value['accepted'] + guestStats.value['invited']
      },
      {
        title:t("Attending"),
        value:guestStats.value['accepted']
      }
    ]); 


    return {
      addCircleOutline,
      enterOutline,
      searchOutline,

      onImportClicked,
      onAddClicked,
      onSearchClicked,
      onStatusClicked,

      router,
      summaryRowList,
      guestStatuses,
      guestStats,

      apiEndpoints,
      store,

    };

  }
});
