import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_ctx.useFab)
    ? (_openBlock(), _createBlock(_component_ion_fab, {
        key: 0,
        slot: "fixed",
        style: {"bottom":"10px","right":"10px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, { onClick: _ctx.onExportClicked }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.shareOutline }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_ion_button, {
        key: 1,
        onClick: _ctx.onExportClicked,
        color: "light",
        size: "small",
        fill: "outline"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Export")) + " ", 1),
          _createVNode(_component_ion_icon, { icon: _ctx.shareOutline }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["onClick"]))
}