import {
  mail,
  checkmarkCircle,
  closeCircle,
  peopleCircle,

} from 'ionicons/icons'
import t from "@/translation"

export interface GuestStatus {
  title: string;
  color: string;
  statusId: number;
  icon: string;
}

export const guestStatuses = new Map<string, GuestStatus>();

guestStatuses.set('invited', {
  title:t("Invited"),
  color:'text',
  statusId:3,
  icon:mail,//helpCircle,
});

guestStatuses.set('accepted', {
  title:t("Accepted"),
  color:'success',
  statusId:0,
  icon:checkmarkCircle,
});

guestStatuses.set('declined', {
  title:t("Declined"),
  color:'danger',
  statusId:2,
  icon:closeCircle,
});

guestStatuses.set('reserved', {
  title:t("Reserved"),
  color:'warning',
  statusId:1,
  icon:peopleCircle,
});

export function guestStatusById(id: number){
  for(const [_key, value] of guestStatuses){
    if (value.statusId == id){
      return value;
    }
  }
  return false;
}