
import { defineComponent } from '@vue/runtime-core'
import { ApiEndpoint, isApiError } from '@/lib/api-endpoints'
import {
 IonFab,
 IonFabButton,
 IonButton,
 IonIcon,
} from '@ionic/vue'

import { shareOutline } from 'ionicons/icons';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { apiRequest } from '@/lib/api-request';
import { Share } from '@capacitor/share';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
  props:{
    shareTitle: {type: String, required: true},
    shareText: {type: String, required: true},
    endPoint: {type: Object, required: true},
    useFab: {type: Boolean, default: true}, 
  },
  inheritAttrs:false,
  components:{
    IonFab,
    IonFabButton,
    IonButton,
    IonIcon,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    async function onExportClicked(){
      console.log("Clicked!")
      if (! store.state.premium.owned){
        router.push({path: '/tabs/bigday'});
        return;
      }
      const filename = props.endPoint.endpoint.replaceAll("/", "_") + '.csv';
      const result = await apiRequest<undefined, string>(props.endPoint as ApiEndpoint);
      if (! isApiError(result)){
        const fileResult = await Filesystem.writeFile({
          path: filename,
          data: result as string,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });
        await Share.share({
          title: props.shareTitle,
          text: props.shareText,
          url: fileResult.uri,
          dialogTitle: "Share Exported File",
        })
      }
      console.log(result);
    }

   return {
     onExportClicked,
     shareOutline,
   };  

  },
})
